import { createSlice, current } from '@reduxjs/toolkit'

export const CartSlice = createSlice({
  name: 'cartSlice',
  initialState: {
    items: []
  },
  reducers: {
    add: (state, action) => {
      if (state.items.length > 0) {
        let product = state.items.find(pr => pr.id === action.payload.id)
        if (product) {
          product.qty += 1;
          const updatedProducts = state.items.map(p => {
            if (product.id === p.id) return product;
            return p;
          });
          state.items = [...updatedProducts]
        } else {
          let p = action.payload
          p.qty = 1
          state.items.push(p)
        }

      } else {
        let p = action.payload
        p.qty = 1
        state.items.push(p)
      }
      // state.items.push(action.payload)
    },
    remove: (state, action) => {
      state.items = [...state.items.filter((p) => p.id !== action.payload.id)]
    },
    update: (state, action) => {
      let product = state.items.find(pr => pr.id === action.payload[0].id)
      if (product) {
        action.payload[1] == 1 ? product.qty += 1 : product.qty -= 1

        if (product.qty == 0)
          state.items = [...state.items.filter((p) => p.id !== action.payload[0].id)]

        const updatedProducts = state.items.map(p => {
          if (product.id === p.id) return product;
          return p;
        });
        state.items = [...updatedProducts]
      }
    },
    clear: (state, action) => {
      state.items = []
    }
  }
})
export const {
  add, remove, clear, update
} = CartSlice.actions
export default CartSlice.reducer
