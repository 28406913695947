import { Link, Outlet } from 'react-router-dom'
import Header from '../shared/header'
import Footer from '../shared/footer'
import { useSelector } from 'react-redux'


const Layout = ({ children }) => {
  const { items } = useSelector((state) => state.cart)

  return (
    <>
      <div>
        <div className="p-4 p-md-5 d-flex align-items-center justify-content-between header-bg">
          <Link className="text-decoration-none text-white display-1" to={'/' }>Fresh Buy Shop</Link>
          <Link className="btn position-relative" to={'/cart' }>
            <svg width="64" version="1.1" viewBox="0 0 128 128" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <path d="M106.456,120H21.544c-2.756,0-5.417-1.153-7.3-3.165c-1.884-2.012-2.86-4.742-2.68-7.491l3.947-60 C15.857,44.104,20.241,40,25.491,40h77.018c5.25,0,9.634,4.104,9.979,9.343l3.947,60.001c0.181,2.749-0.796,5.479-2.68,7.491     C111.873,118.847,109.212,120,106.456,120z M25.491,44c-3.15,0-5.78,2.463-5.987,5.606l-3.947,59.999     c-0.11,1.675,0.46,3.271,1.606,4.495c1.147,1.225,2.703,1.899,4.381,1.899h84.912c1.678,0,3.233-0.675,4.381-1.899     c1.146-1.225,1.717-2.82,1.606-4.495l-3.947-60c-0.207-3.143-2.837-5.605-5.987-5.605H25.491z" fill="#fff" />
              <path d="M97,56c-1.104,0-2-0.896-2-2v-4.5C95,28.271,81.094,11,64,11S33,28.271,33,49.5V54 c0,1.104-0.896,2-2,2s-2-0.896-2-2v-4.5C29,26.065,44.701,7,64,7s35,19.065,35,42.5V54C99,55.104,98.104,56,97,56z" fill="#fff" />
            </svg>
            <span className="fw-bold fs-6 text-white position-absolute cart-bag" style={{ right: items?.length>9? '2.2rem' : '2.5rem' }}>{ items?.length }</span>
          </Link>
        </div>
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default Layout
