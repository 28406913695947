import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Product from './product'

const Products = () => {
  const [products, setProducts] = useState([
    { id: '1', name: 'Potato', price: 25, mrp: 35, unit:'1 kg', img: 'https://cdn.pixabay.com/photo/2012/12/24/08/39/agriculture-72254_960_720.jpg' },
    { id: '2', name: 'Onion', price: 25, mrp: 35, unit: '1 kg', img: 'https://cdn.pixabay.com/photo/2013/02/21/19/14/onion-bulbs-84722_960_720.jpg' },
    { id: '3', name: 'Garlic', price: 25, mrp: 35, unit: '250 gms', img: 'https://cdn.pixabay.com/photo/2013/11/28/12/15/garlic-220495_960_720.jpg' },
    { id: '4', name: 'Ginger', price: 25, mrp: 35, unit: '250 gms', img: 'https://cdn.pixabay.com/photo/2017/01/07/14/56/ginger-1960613_960_720.jpg' },
    { id: '5', name: 'Cauliflower', price: 25, mrp: 35, unit: '300 - 600 gms', img: 'https://cdn.pixabay.com/photo/2015/09/25/16/51/cabbage-957778_960_720.jpg' },
    { id: '6', name: 'Cabbage', price: 25, mrp: 35, unit: '500 - 800 gms', img: 'https://cdn.pixabay.com/photo/2014/04/05/11/39/kale-316559_960_720.jpg' },
    { id: '7', name: 'Tomato', price: 20, mrp: 30, unit: '500 gms', img: 'https://cdn.pixabay.com/photo/2015/08/05/20/20/tomato-877019_960_720.jpg' },
    { id: '8', name: 'Carrot', price: 30, mrp: 60, unit: '500 gms', img: 'https://cdn.pixabay.com/photo/2016/09/25/16/34/carrots-1694087_960_720.jpg' },
    { id: '9', name: 'Green Chilli', price: 25, mrp: 35, unit: '100 gms', img: 'https://cdn.pixabay.com/photo/2017/08/14/19/11/green-chilli-2641573_960_720.jpg' },
  ])

  useEffect(() => {
    
  }, [])

  return (
    <div className="cards">
      {
        products.map((p,i) => (<Product key={ `p-${i}`} p={ p }/>))
      }
      
    </div>
  )
}
export default Products
