import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { remove, update, clear } from '../../redux/slices/cartSlice'

const Cart = () => {
  const dispatch = useDispatch()
  const { items } = useSelector((state) => state.cart)

  useEffect(() => {

  }, [items])

  const updateCart = (p, qty) => {
    dispatch(update([p, qty]))
  }

  const removeCart = (p) => {
    dispatch(remove(p))
  }

  const clearCart = () => {
    dispatch(clear())
  }

  return (
    <div className="container-fluid">
      <h3 className="display-3 m-4">Cart</h3>
      {items?.length===0 &&
        <div className="text-center m-5">
          <h4 className="mb-4">There is nothing in your cart!</h4>
          <Link className="btn btn-primary btn-lg" to={'/'}>Start shopping</Link>
        </div>
      }
      <div className="row">
        <div className="col-md-6">
          {items?.length>0 &&
            <div className="text-end">
              <button className="btn btn-light" onClick={()=> clearCart() }>Clear Cart</button>
            </div>
          }
          {
            items?.map((p) => (
              <div key={`cartitem-${p?.id}`} className="row align-items-center p-2 border-bottom my-2">
                <div className="col-md-7 d-flex align-items-center">
                  <img className="img-fluid" src={p?.img} style={{ maxHeight: '3rem' }} />
                  <div className="fs-5 px-3">{p?.name}</div>
                </div>
                
                <div className="col-md-2 fs-5">
                  <div className="btn-group">
                    <button onClick={() => updateCart(p, -1)} className="btn btn-light" style={{minWidth:'3rem'}}>-</button>
                    <span className="px-3">{p?.qty}</span>
                    <button onClick={() => updateCart(p, 1)} className="btn btn-light" style={{ minWidth: '3rem' }}>+</button>
                  </div>
                </div>
                <div className="col-md-2 fw-bold px-4">₹{p?.price}</div>
                <div className="col-md-1 px-4">
                  <button className="btn btn-sm btn-outline-secondary" onClick={() => removeCart(p) }>Remove</button>
                </div>
              </div>
            ))
          }
        </div>
        {items?.length > 0 &&
          <div className="col-md-6">
            <div className="text-center">
              <button className="btn btn-lg btn-success">Checkout</button>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default Cart
