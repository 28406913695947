import { Route, Routes } from 'react-router-dom'
import Layout from './app/components/shared/layout'
import Container from './app/components/shared/container'
import Home from './app/components/home'
import Cart from './app/components/cart'


function App() {
  return (
    <Routes>
      <Route element={<Container />}>
        <Route path="*" component={<Home />} status={404} />
      </Route>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/cart" element={<Cart />} />
      </Route>
    </Routes>
  )
}

export default App
