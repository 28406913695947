import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { add, remove } from '../../redux/slices/cartSlice'

const Product = ({ p }) => {
  const dispatch = useDispatch()

  useEffect(() => {

  }, [])

  const addToCart = (p) => {
    dispatch(add(p))
  }
  
  return (
    <div className="product-card">
      <div className="product-card__image">
        <img className="img-fluid" src={ p?.img } />
      </div>
      <div className="p-3 pb-0 position-relative mb-3 d-flex flex-grow-1 flex-column justify-content-end">
        <h5>{p?.name}</h5>
        <small className="text-muted">{p?.unit}</small>
        <div className="d-flex my-2 align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-between">
            <div className="fw-bold fs-5 me-1 me-md-3">₹{p?.price}</div>
            <s className="fs-6 text-muted">₹{p?.mrp}</s>
          </div>
          <button className="btn btn-outline-primary btn-sm" onClick={()=> addToCart(p) }>Add</button>
        </div>
      </div>
    </div>
  )
}
export default Product
