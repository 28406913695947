import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Products from './products'

const Home = () => {

  useEffect(() => {
    
  }, [])

  return (
    <div>
      
      <div className="container">
        {/*<h1 className="display-4">egbg</h1>*/}
        {/*<h5 className="text-muted small">Product Market Place</h5>*/}
        {/*<div className="text-center mt-5">*/}
        {/*  <div className="gradient-text h1">You're welcome.</div>*/}
        {/*  <div className="mt-4 h4">We're modernizing this website. Thank you</div>*/}
        {/*  <div className="h3 gradient-text">List and Sell your products</div>*/}
        {/*</div>*/}
        {/*<div className="my-5 py-5 text-center">*/}
        {/*  <button className="btn btn-lg btn-primary rounded-1">List my products</button>*/}
        {/*</div>*/}
      </div>
      <Products/>
    </div>
  )
}
export default Home
